import create from 'zustand';
import { combine } from 'zustand/middleware';

type ModuleName = '约课提示' | '最近课程' | '课程回顾缺席' | '课程回顾出席' | '为你推荐' | '';

const useModuleName = create(
  combine({ moduleName: '' } as { moduleName: ModuleName }, (set) => ({
    updateModuleName: (moduleName: ModuleName) => {
      set(() => ({ moduleName }));
    },
  })),
);

export default useModuleName;
