/* eslint-disable @next/next/no-img-element */
import React, { useEffect, useState } from 'react';
import cls from 'classnames';
import { traceEvent } from '@wk/wk-gatherer';
import { useTranslation } from 'next-i18next';
import { getTeacherInfo } from '@/api/trialStudyReport';
import { GetTeacherInfoRes } from '@/api/types/trialStudyReport';
import { useRouter } from 'next/router';
import { avatorDefault } from '@/pageComponents/Home/TrialCourse/constants';
import bgImgTop from '@public/imgs/subscri-m-bg-bottom.png';
import bgImgBottom from '@public/imgs/subscri-m-bg-top.png';
import styles from './style.module.scss';

interface SubscriptionEsProps {
  isGs2?: any;
  stuScheduleContent?: any;
  curStudentUid?: any;
}

const SubscriptionEs = (props: SubscriptionEsProps) => {
  const { t, i18n } = useTranslation('home');
  const router = useRouter();
  const [teacherInfo, setTeacherInfo] = useState<GetTeacherInfoRes['content']>();
  const isZh = i18n.language === 'zh';

  const getTeacher = async () => {
    const results = await getTeacherInfo();
    setTeacherInfo(results.content);
  };

  const handleToSubscriptionDetail = () => {
    const data = {
      _event: 'U_HP_Banner_SubscribeEnterClick',
      teacherId: teacherInfo?.teacher?.uuid,
      courseStatus: props.stuScheduleContent.stuScheduleStatus,
      studentType: props.isGs2 ? 'GS2.0' : '普通学员',
    };
    traceEvent({
      data,
    });
    router.push({
      pathname: `/home/subscriptionDetails`,
      query: { triggerEvent: 'U_HP_Banner_SubscribeEnterClick' },
    });
  };

  useEffect(() => {
    getTeacher();
  }, [props.curStudentUid]);

  useEffect(() => {
    if (props.stuScheduleContent.display) {
      const data = {
        _event: 'U_HP_Banner_SubscribeEnterExpose',
        teacherId: teacherInfo?.teacher?.uuid,
        courseStatus: props.stuScheduleContent.stuScheduleStatus,
        studentType: props.isGs2 ? 'GS2.0' : '普通学员',
      };
      traceEvent({
        data,
      });

      const dataTop = {
        _event: 'U_HP_SubscribeEnterExpose',
        teacherId: teacherInfo?.teacher?.uuid,
        courseStatus: props.stuScheduleContent.stuScheduleStatus,
        studentType: props.isGs2 ? 'GS2.0' : '普通学员',
      };
      traceEvent({
        data: dataTop,
      });
    }
  }, [props.stuScheduleContent.display]);

  if (!props.stuScheduleContent.display) {
    return null;
  }

  return (
    <div className={cls(styles.container, 'mb-[28px] last:mb-0 mobile:bg-white mobile:w-[calc(100vw-32px)] mobile: mx-auto')}>
      <div
        className={cls(
          styles.footer,
          props.stuScheduleContent.stuScheduleStatus &&
            props.stuScheduleContent.stuScheduleStatus !== 'CANCELLED' &&
            styles.footerS,
        )}
      >
        <div
          className={cls(
            styles.footerInfo,
            props.stuScheduleContent.stuScheduleStatus &&
              props.stuScheduleContent.stuScheduleStatus !== 'CANCELLED' &&
              styles.hide,
          )}
        >
          <h3 className={styles.footerInfoTitle}>{t('GS3订阅入口-未约课-标题')}</h3>
          <p className={styles.footerInfoDes}>{t('GS3订阅入口-未约课-副标题')}</p>
        </div>
        <div
          className={cls(
            styles.footerTeacherInfo,
            (!props.stuScheduleContent.stuScheduleStatus ||
              props.stuScheduleContent.stuScheduleStatus === 'CANCELLED') &&
              styles.hide,
          )}
        >
          <img className={styles.avatarImg} src={teacherInfo?.teacher?.imageUrl || avatorDefault.src} alt="" />
          <div className={styles.teacherInfoBox}>
            <h3 className={styles.teacherName}>
              {(isZh ? teacherInfo?.teacher?.fullName : teacherInfo?.teacher?.gsName) || teacherInfo?.teacher?.fullName}
            </h3>
            <p className={styles.teacherLevel}>{t('GS3订阅入口-已约课-标题')}</p>
            <p className={cls(styles.teacherDes, styles.teacherDesP)}>
              {t('GS3订阅入口-已约课-前-副标题')}
              {(isZh ? teacherInfo?.teacher?.fullName : teacherInfo?.teacher?.gsName) || teacherInfo?.teacher?.fullName}
              {t('GS3订阅入口-已约课-后-副标题')}
            </p>
          </div>
        </div>
        <p
          className={cls(
            styles.teacherDesM,
            (!props.stuScheduleContent.stuScheduleStatus ||
              props.stuScheduleContent.stuScheduleStatus === 'CANCELLED') &&
              styles.hide,
          )}
        >
          <div className={cls(styles.teacherDesMBox)}>
            {t('GS3订阅入口-已约课-前-副标题')}
            {(isZh ? teacherInfo?.teacher?.fullName : teacherInfo?.teacher?.gsName) || teacherInfo?.teacher?.fullName}
            {t('GS3订阅入口-已约课-后-副标题')}
          </div>
        </p>
        <div
          className={cls(
            styles.footerInfoBtn,
            (props.stuScheduleContent.stuScheduleStatus === 'ABSENT' ||
              props.stuScheduleContent.stuScheduleStatus === 'COMPLETED') &&
              styles.footerInfoBtnComplate,
          )}
          onClick={handleToSubscriptionDetail}
        >
          {t('GS3订阅入口-按钮')}
        </div>

        <img src={bgImgTop.src} alt="" className={cls(styles.bgImgTop)} />
        <img src={bgImgBottom.src} alt="" className={cls(styles.bgImgBottom)} />
      </div>
    </div>
  );
};

export default SubscriptionEs;
