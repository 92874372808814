import { useTranslation } from 'next-i18next';
import cls from 'classnames';
import _ from 'lodash';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import styles from './style.module.scss';
import { Toast } from '@/components';
import { IMemoReportProps } from '@/pageComponents/Home/TrialCourse/interface';
import { CLASS_REPORT } from '@/pageComponents/Home/TrialCourse/ClassReport';
import { TRIAL_CARD_VIEW_REPORT_CLICK } from '@/pageComponents/Home/TrialCourse/constants';
import { traceEvent } from '@wk/wk-gatherer';
import TrialTraceDataContext from '@/pageComponents/Home/TrialCourse/TrialTraceDataContext';
import { useContext } from 'react';
import { getStudyReportUrlWithLang } from '@/utils/gs2';
type IProps = {
  isReaded: boolean;
};
const TrialStep3 = ({
  crmReport,
  memorizedSetReport,
  courseScheduleId,
  isReaded,
}: IProps & IMemoReportProps & Partial<ICourseInfo>) => {
  const { t, i18n } = useTranslation(['trailCourse']);
  const traceData = useContext(TrialTraceDataContext);
  const hasReport = !!crmReport?.trialReportUrl;

  // 跳转学习报告
  const goTrialStudyReport = () => {
    !isReaded && memorizedSetReport?.(CLASS_REPORT, courseScheduleId as string);
    const jumpUrl = getStudyReportUrlWithLang(crmReport?.trialReportUrl, i18n.language);
    window.open(jumpUrl);
  };
  return (
    <div className={styles.classReportOperate}>
      <div className={styles.checkStep}>
        <div className={styles.tip}>{t('查看体验课报告-描述')}</div>
      </div>
      <div
        className={cls(styles.step3Btn, hasReport ? styles.step3BtnActive : styles.step3BtnNormal)}
        onClick={() => {
          traceEvent({
            data: {
              _event: TRIAL_CARD_VIEW_REPORT_CLICK,
              ..._.pick(traceData, ['courseScheduleId', 'courseType', 'subject', 'courseStatus', 'reportStatus']),
            },
          });
          if (!hasReport) {
            Toast.warning(t('暂无体验课报告'));
            return;
          }
          goTrialStudyReport();
        }}
      >
        {t('按钮-了解详情')}
      </div>
    </div>
  );
};

export default TrialStep3;
