import { COMMON_API_HOST as COMMON_API, WK_GATEWAY_URL } from '@/utils/env';
import { EventTrackingRes, EventTrackingReq, AppContent, ContentData, IWechatConfigResponse } from './types/common';
import client from './axios';
import { getAPIBaseUrl } from '@/utils/url';

const COMMON_API_HOST = getAPIBaseUrl(COMMON_API);

export interface GenerateSchemeRes {
  content: string;
}

export interface GenerateSchemeReq {
  path?: string;
  query?: string;
  lang?: string;
}

export interface Country {
  areaCode: string;
  name: string;
  value: string;
}

export interface CountriesRes {
  local: Country;
  countries: Country[];
}

export interface DeviceDetectionRes {
  studentCode: string;
  platform: string;
  data: object;
}

type GetDeviceDetectionResData = {
  deviceDetection: boolean;
  courseReportList: string[];
  [key: string]: any;
};

export interface GetDeviceDetectionRes {
  platform: string;
  data: GetDeviceDetectionResData;
}

// eslint-disable-next-line import/prefer-default-export
export const sendEventTracking = (params: EventTrackingReq) => {
  return client.post<EventTrackingRes, EventTrackingRes>(`${COMMON_API_HOST}/public/eventTracking/upload`, {
    ...params,
  });
};

// 获取小程序scheme码
export const getGenerateScheme = (params: GenerateSchemeReq) => {
  return client.get<GenerateSchemeRes, GenerateSchemeRes>(`${COMMON_API_HOST}/public/api/wechat/share/generateScheme`, {
    params,
  });
};
// 获取app版本信息
export const getAppsSource = () => {
  return client
    .get<ContentData<AppContent>, ContentData<AppContent>>(`/public/app/info/download`)
    .then((res) => res?.content);
};

export const GetCountryList = () => {
  return client.get<ContentData<CountriesRes>, ContentData<CountriesRes>>(`/public/open/countries/zh/ddl/local`);
};

export interface User {
  accountData: {
    alreadySetPassword: boolean;
    associatedWeChat: boolean;
    registerBind: boolean;
  };
  token: string;
  refreshToken: string;
  uuid: string;
  email: string | null;
  errorCode?: number;
  phone: string | null;
  fullName?: string;
  oauthType?: string;
  openId?: string;
  unionId?: string;
  leadRepeat?: boolean;
}

export interface RefreshTokenRes {
  content: User;
}

export const GetTokenByRefreshToken = () => {
  return client.post<RefreshTokenRes, RefreshTokenRes>('/api/authorized/refresh_token');
};

export type TimezoneRes = {
  label: string;
  value: string;
}[];

export const GetTimezoneList = () => {
  return client.get<ContentData<TimezoneRes>, ContentData<TimezoneRes>>(`/public/open/timezones/ddl`);
};

/**
 * 获取时间
 * @returns 时间戳
 */
export const getTimestamp = () => {
  return client.get<number, number>(`${COMMON_API_HOST}/public/timestamp`);
};

// 上报用户设置检测结果 仅在成功的时候才上报
export const createDeviceDetect = (data: DeviceDetectionRes) => {
  return client.post(`${WK_GATEWAY_URL}/ms-user/api/user/device/create`, data);
};

// 获取用户设备检测结果列表
export const getDeviceDetect = (data: { studentCode: string }) => {
  return client.post<GetDeviceDetectionRes[], GetDeviceDetectionRes[]>(
    `${WK_GATEWAY_URL}/ms-user/api/user/device/list`,
    data,
  );
};

export const getWeixinSign = (params: string) => {
  return client.get<IWechatConfigResponse, IWechatConfigResponse>(
    `${COMMON_API_HOST}/public/api/wechat/share/js/sdk/config?url=${encodeURIComponent(params)}`,
  );
};
