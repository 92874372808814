import * as React from 'react';

export type TrialCourseTraceDataProps = {
  courseType: string;
  courseScheduleId: string;
  subject: string;
  preparationStatus: boolean;
  previewStatus: boolean;
  courseStatus: string;
  scheduleName: string;
  reportStatus: boolean;
  homeworkStatus: boolean;
};

export default React.createContext<Partial<TrialCourseTraceDataProps>>({});
