import { useEffect, useState, useRef, useCallback } from 'react';
import { setInterval, clearInterval } from 'worker-timers';

/**
 * @param countDownTime 倒计时差值(秒)
 * @returns [差值, 小时, 分钟, 秒]
 */
const useCountDown = (countDownTime: number): [number, number, number, number] => {
  const [time, setTime] = useState(countDownTime);
  const intervalRef = useRef<number | null>(null);

  const calculateTime = useCallback((t: number) => {
    const hour = Math.floor(t / 3600);
    const minute = Math.floor((t / 60) % 60);
    const second = Math.floor(t % 60);
    return [t, hour, minute, second] as [number, number, number, number];
  }, []);

  useEffect(() => {
    if (time > 0) {
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => Math.max(prevTime - 1, 0));
      }, 1000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [time]);

  return calculateTime(time);
};

export default useCountDown;
