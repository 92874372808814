import client from './axios';
import { GetTeacherInfoRes } from './types/trialStudyReport';

/**
 * 获取老师信息
 * @returns GetTeacherInfoRes
 */
export const getTeacherInfo = () => {
  return client.get<GetTeacherInfoRes, GetTeacherInfoRes>(`/api/reserve/gs_teacher_info_V2`);
};
