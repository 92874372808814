import { ReactNode, useRef, useState } from 'react';
import cls from 'classnames';
import styles from './style.module.scss';
import { Collapse as CollapsePanel } from 'react-collapse';
import StokeArrowUp from '@/components/Icon/stokeArrowUp';
export const ArrowDirectionMap = {
  ARROW_DIRECTION_LEFT: 'justify-start',
  ARROW_DIRECTION_BETWEEN: 'justify-between',
} as const;
type TArrowDirection = (typeof ArrowDirectionMap)[keyof typeof ArrowDirectionMap];
interface IProps {
  defaultExpand?: boolean; // true: 展开, false: 折叠
  children?: ReactNode;
  title?: ReactNode;
  onCollapse?: (isCollapse: boolean) => void;
  arrowDirection?: TArrowDirection;
}

const Collapse = (props: IProps) => {
  const {
    children,
    title,
    onCollapse,
    defaultExpand = false,
    arrowDirection = ArrowDirectionMap.ARROW_DIRECTION_BETWEEN,
  } = props;
  const contentRef = useRef(null);
  const [isExpand, setIsExpand] = useState(defaultExpand);

  const handleCollapse = () => {
    setIsExpand(!isExpand);
    onCollapse?.(!isExpand);
  };
  return (
    <div className={styles.collapseBox}>
      <div onClick={handleCollapse} className={cls(styles.collapseBar, `${arrowDirection}`)}>
        {title}
        <StokeArrowUp className={cls('h-[13px] w-[13px] transition-all', isExpand ? '' : 'rotate-[-180deg]')} />
      </div>
      <CollapsePanel isOpened={isExpand} className={styles.add} initialStyle={{ height: '0px', overflow: 'hidden' }}>
        <div className={styles.content} ref={contentRef}>
          {children}
        </div>
      </CollapsePanel>
    </div>
  );
};
export default Collapse;
