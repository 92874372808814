import qs from 'query-string';
import { useTranslation } from 'next-i18next';
import _ from 'lodash';
import styles from './style.module.scss';
import cls from 'classnames';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { IMemoReportProps } from '../../../interface';
import { CURRENT_STUDENT_CODE } from '@/utils/constants';
import { LEARN_PLAN_KEY } from '../..';
type IProps = {
  isReaded: boolean;
};
const TrialStepLearnPlan = ({
  isReaded,
  courseScheduleId,
  memorizedSetReport,
  courseSubject,
}: IProps & IMemoReportProps & Partial<ICourseInfo>) => {
  const { t, i18n } = useTranslation(['trailCourse']);
  const routeToEdm = () => {
    !isReaded && memorizedSetReport?.(LEARN_PLAN_KEY, courseScheduleId as string);
    const url = `${process.env.NEXT_PUBLIC_LANDING_PATH}/${i18n.language}/edm?${qs.stringify({
      studentCode: window.sessionStorage.getItem(CURRENT_STUDENT_CODE),
      subject: courseSubject,
      source: 'studentCenter',
    })}`;
    window.open(url);
  };
  return (
    <div className={styles.homeworkOperate}>
      <div className={styles.checkStep}>
        <div className={styles.tip}>{t('定制学习计划-描述')}</div>
      </div>
      <div
        className={cls(styles.step2Btn, isReaded ? styles.step2BtnDisable : styles.step2BtnActive)}
        onClick={routeToEdm}
      >
        {t('联系你的规划师')}
      </div>
    </div>
  );
};

export default TrialStepLearnPlan;
