import styles from './style.module.scss';
import cls from 'classnames';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { ITaskState } from '../interface';
import { TASK_COMPLETED, TASK_NORMAL, TASK_PROCESSING } from '../constants';

type IProps = {
  step: number;
  taskState: ITaskState;
  isPlot?: boolean;
};
const currentStyle: Record<string, string> = {
  [TASK_NORMAL]: styles.taskNormal,
  [TASK_COMPLETED]: styles.taskCompleted,
  [TASK_PROCESSING]: styles.taskProcessing,
};
// 需要的是状态 未开始NORMAL / 进行中PROCESSING(需要给collapse展开) / 已完成COMPLETED
const StepNumber = ({ taskState, isPlot = false, step = 1 }: IProps & Partial<ICourseInfo>) => {
  return (
    <>
      {[TASK_NORMAL, TASK_PROCESSING].includes(taskState) ? (
        <div className={cls(styles.stepNumber, currentStyle[taskState])}>
          <span>{step + 1}</span>
          {isPlot && <span className={styles.redPlot} />}
        </div>
      ) : (
        <div className={styles.taskCompleted}>
          <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill="none">
            <path
              d="M2.09766 6.5L4.86295 9.26529C5.03032 9.43266 5.30167 9.43266 5.46904 9.26529L10.2799 4.45444"
              stroke="white"
              strokeWidth="2.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default StepNumber;
