import { useTranslation } from 'next-i18next';
import Collapse from '../Collapse';
import Step from './components/Step';
import StepNumber from '../StepNumber';
import { PREPARE, TASK_COMPLETED, TASK_NORMAL, TASK_PROCESSING } from '../constants';
import styles from './style.module.scss';
import { IMemoReportProps, ITaskState } from '../interface';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { useEffect, useState } from 'react';

type IProps = {
  step: number;
  course?: Partial<ICourseInfo>;
  deviceTested?: boolean;
  availableListLen?: number;
  handleTaskCollapse?: (expand: boolean) => void;
  isPreviewed?: boolean;
  canEnter?: boolean;
};
export const PRE_CLASS_PREVIEW: string = 'preClassPreview';

const Prepare = ({
  course,
  step,
  deviceTested,
  memorizedSetReport,
  isPreviewed,
  canEnter,
  handleTaskCollapse,
}: IProps & IMemoReportProps) => {
  const { t } = useTranslation(['trailCourse']);
  /**
   * 节点一进行中(透明红背景或者灰色)状态: 未完成(展开透明红, 收起灰色)
   * 节点一已完成(绿色打钩)状态: 完成(设备检测+课前预习)
   */
  const [taskState, setTaskState] = useState<ITaskState>(TASK_NORMAL);
  const handleCollapse = (expand: boolean) => {
    handleTaskCollapse?.(expand);
    // 判断任务进行状态
    const isCompleted = course?.hasTeachingMaterials ? !!(deviceTested && isPreviewed) : !!deviceTested;
    if (isCompleted) {
      setTaskState(TASK_COMPLETED);
    } else {
      setTaskState(expand ? TASK_PROCESSING : TASK_NORMAL);
    }
  };

  useEffect(() => {
    const isCompleted = course?.hasTeachingMaterials ? !!(deviceTested && isPreviewed) : !!deviceTested;
    setTaskState(isCompleted ? TASK_COMPLETED : TASK_NORMAL);
  }, [isPreviewed, deviceTested, course?.hasTeachingMaterials]);

  return (
    <>
      <StepNumber taskState={taskState} step={step} />
      <Collapse
        defaultExpand={true}
        title={<span className={styles.stepBarTitle}>{t('课前准备')}</span>}
        onCollapse={handleCollapse}
      >
        <Step
          key={PREPARE}
          canEnter={canEnter}
          deviceTested={deviceTested as boolean}
          memorizedSetReport={memorizedSetReport}
          isPreviewed={isPreviewed as boolean}
          {...course}
        />
      </Collapse>
    </>
  );
};

export default Prepare;
