import styles from './style.module.scss';
import arrowRight from '@public/imgs/trail-course/arrow-right.png';
import Image from 'next/image';
import { useContext, useEffect } from 'react';
import qs from 'query-string';
import _ from 'lodash';
import router from 'next/router';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import {
  TRIAL_CARD_TO_PREPARATION_CLICK,
  TRIAL_CARD_TO_PREVIEW_CLICK,
} from '@/pageComponents/Home/TrialCourse/constants';
import { useTranslation } from 'next-i18next';
import { Toast } from '@/components';
import { HOMEWORK_URL } from '@/utils/env';
import { mainDomainTokenSet } from '@/utils/utils';
import { canViewClassData } from '@/api/home';
import { IMemoReportProps } from '@/pageComponents/Home/TrialCourse/interface';
import { PRE_CLASS_PREVIEW } from '@/pageComponents/Home/TrialCourse/Prepare';
import { traceEvent } from '@wk/wk-gatherer';
import TrialTraceDataContext from '@/pageComponents/Home/TrialCourse/TrialTraceDataContext';
type IProps = {
  deviceTested: boolean;
  canEnter?: boolean;
  isPreviewed: boolean;
};

const TrialStep1 = ({
  deviceTested,
  isPreviewed,
  courseScheduleId,
  courseSubject,
  hasTeachingMaterials,
  memorizedSetReport,
  sectionNameDisplay,
  sectionNameDisplayEn,
}: Partial<ICourseInfo> & IMemoReportProps & IProps) => {
  const { t, i18n } = useTranslation('trailCourse');
  const { t: homeT } = useTranslation('home');
  const traceData = useContext(TrialTraceDataContext);
  const isZh = i18n.language === 'zh';

  useEffect(() => {
    const handleRouteChange = router.events.on('routeChangeComplete', (url) => {
      if (url.includes('/home/iexercise') && !isPreviewed) {
        // 标记点过[去预习]
        memorizedSetReport?.(PRE_CLASS_PREVIEW, courseScheduleId as string);
      }
    });
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange as any);
    };
  }, []);

  // 去预习
  const routeToPreview = async () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_TO_PREVIEW_CLICK,
        ..._.pick(traceData, [
          'courseScheduleId',
          'courseType',
          'subject',
          'courseStatus',
          'preparationStatus',
          'previewStatus',
        ]),
      },
    });
    const result = await canViewClassData(courseScheduleId as string);
    if (result) {
      mainDomainTokenSet();
      const url = `${HOMEWORK_URL()}/${i18n.language}/material?courseScheduleId=${courseScheduleId}`;
      router.push({
        pathname: `/home/iexercise`,
        query: { url, backUrl: router.asPath },
      });
    } else {
      Toast.warning(homeT('无法进入本科重点'));
    }
  };
  const checkPass = (
    <div className={styles.checkPass}>
      <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
        <path
          d="M2.09766 6L4.86295 8.76529C5.03032 8.93266 5.30167 8.93266 5.46904 8.76529L10.2799 3.95444"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  );
  const checkNoPass = <div className={styles.checkBox} />;

  const routeToHowClass = () => {
    traceEvent({
      data: {
        _event: TRIAL_CARD_TO_PREPARATION_CLICK,
        ..._.pick(traceData, [
          'courseScheduleId',
          'courseType',
          'subject',
          'courseStatus',
          'preparationStatus',
          'previewStatus',
        ]),
      },
    });
    const url = qs.stringifyUrl({
      url: `/howClass`,
      query: {
        courseScheduleId: courseScheduleId,
        scheduleName: isZh ? sectionNameDisplay : sectionNameDisplayEn,
        subject: courseSubject,
      },
    });
    router.push(url);
  };
  return (
    <div className={styles.prepareOperate}>
      <div className={styles.checkStep}>
        <div className={styles.tip}>{t('课前准备-描述')}</div>
        <div className={styles.checkItem}>
          <div className={styles.checkInfo}>
            {deviceTested ? checkPass : checkNoPass}
            <div className={styles.checkText}>{`${t('上课设备检测')} (1${isZh ? '' : ' '}${t('分钟')})`}</div>
          </div>
          <div className={styles.gotoTest}>
            <div className={styles.testBtn} onClick={routeToHowClass}>
              <span className={deviceTested ? styles.complete : styles.testNow}>
                {deviceTested ? t('按钮-已完成') : t('按钮-去检测')}
              </span>
            </div>
            <Image src={arrowRight} width={12} height={12} alt="" />
          </div>
        </div>
        {hasTeachingMaterials && (
          <div className={styles.checkItem}>
            <div className={styles.checkInfo}>
              {isPreviewed ? checkPass : checkNoPass}
              <div className={styles.checkText}>{`${t('课前知识点预习')} (2${isZh ? '' : ' '}${t('分钟')})`}</div>
            </div>
            <div className={styles.gotoTest}>
              <div className={styles.testBtn} onClick={routeToPreview}>
                <span className={isPreviewed ? styles.complete : styles.testNow}>
                  {isPreviewed ? t('按钮-已完成') : t('按钮-去预习')}
                </span>
              </div>
              <Image src={arrowRight} width={12} height={12} alt="" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default TrialStep1;
