import { useEffect, useState } from 'react';
import Collapse from '../Collapse';
import StepNumber from '../StepNumber';
import { HOMEWORK, TASK_COMPLETED, TASK_NORMAL, TASK_PROCESSING } from '../constants';
import { IMemoReportProps, ITaskState } from '../interface';
import styles from './style.module.scss';
import { ICourseInfo } from '@/pageComponents/CancelCourse/interface';
import { useTranslation } from 'next-i18next';
import Step from './components/Step';

type IProps = {
  step: number;
  course?: Partial<ICourseInfo>;
};
export const LEARN_PLAN_KEY: string = 'learnPlan';
const LearnPlan = ({ course, step, reportList, memorizedSetReport }: IProps & IMemoReportProps) => {
  const { t } = useTranslation(['trailCourse']);
  const { courseScheduleId } = course || {};
  const isReaded = !!reportList?.[LEARN_PLAN_KEY]?.includes(courseScheduleId as string);
  const [taskState, setTaskState] = useState<ITaskState>(TASK_NORMAL);

  const handleExpand = (expand: boolean) => {
    if (isReaded) {
      // 如果查看过顾问, 一直为绿色, 不分展开收起
      setTaskState(TASK_COMPLETED);
    } else {
      // 未查看: 展开: 粉色 收起: 灰色
      setTaskState(expand ? TASK_PROCESSING : TASK_NORMAL);
    }
  };

  useEffect(() => {
    setTaskState(isReaded ? TASK_COMPLETED : TASK_NORMAL);
  }, [isReaded]);

  return (
    <>
      <StepNumber taskState={taskState} step={step} isPlot={!isReaded} />
      <Collapse
        defaultExpand={true}
        onCollapse={handleExpand}
        title={<span className={styles.stepBarTitle}>{t('定制学习计划')}</span>}
      >
        <Step {...course} key={HOMEWORK} isReaded={isReaded} memorizedSetReport={memorizedSetReport} />
      </Collapse>
    </>
  );
};

export default LearnPlan;
