import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'next-i18next';
import { IS_PROD, WUKONG_DOMAIN } from '@/utils/env';
import { TEST_DEVICE_RESULT } from '@/utils/constants';
import useUser from '@/store/useUser';
import { createDeviceDetect, getDeviceDetect } from '@/api/common';

import { isObject } from 'lodash';
export type TestResultType = 'true' | 'false' | null;

interface IReportList {
  [key: string]: string[];
}

const useDeviceInfo = (callBack?: (r: TestResultType) => void) => {
  const { i18n } = useTranslation('home');
  const [testResult, setTestResult] = useState<TestResultType>(null);
  const { curStudent } = useUser();
  const [studentResult, setStudentResult] = useState<boolean>();
  const [courseReportList, setCourseReportList] = useState<string[]>([]);
  const [reportList, setReportList] = useState<IReportList>({});
  useEffect(() => {
    const b = localStorage.getItem(TEST_DEVICE_RESULT) as TestResultType;
    setTestResult(b);
    if (!curStudent?.code) return;
    (async () => {
      const res = await getDeviceDetect({ studentCode: curStudent?.code as string });
      // 目前暂时不区分区分平台，只要检测通过都认为检测通过了
      setStudentResult(!!res?.[0]?.data?.deviceDetection);
      setCourseReportList(res?.[0]?.data?.courseReportList || []);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { deviceDetection, ...rest } = res?.[0]?.data || {};
      setReportList(rest || {});
    })();
  }, [setTestResult, setStudentResult, curStudent, setReportList]);

  const memorizedGetTKUrl = useCallback(
    (pid: string = '') => {
      return `https://h5-static.talk-cloud.net/static/wukong_device${
        IS_PROD ? '' : '_demo'
      }/index.html#/?domain=${WUKONG_DOMAIN}&ts=${Date.now()}&lang=${
        i18n.language === 'zh' ? 'zh-cn' : 'en-us'
      }&iswizard=1&isreset=1&pid=${pid}`;
    },
    [i18n.language],
  );

  const memorizedGetTestDeviceResult = useCallback(() => {
    const r = localStorage.getItem(TEST_DEVICE_RESULT);
    setTestResult(r as TestResultType);
  }, [setTestResult]);

  const memorizedSetTestDeviceResult = useCallback(
    async (e) => {
      try {
        let v = isObject(e.data) ? e.data : JSON.parse(e.data || '{}');
        if (v?.data?.action === 'deviceResult') {
          const r = v?.data?.result.toString();
          setTestResult(r);
          if (r === 'true') {
            const payload = {
              studentCode: curStudent?.code as string,
              platform: 'PC',
              data: {
                deviceDetection: true,
              },
            };
            await createDeviceDetect(payload);
          }
          setStudentResult(r === 'true');
          callBack && callBack(r);
          localStorage.setItem(TEST_DEVICE_RESULT, r);
          memorizedGetTestDeviceResult();
        }
      } catch (e) {
        console.error(e);
      }
    },
    [memorizedGetTestDeviceResult],
  );

  const memorizedHandleOpenTKWindow = useCallback(
    (pid?: string) => {
      const t = window.open(memorizedGetTKUrl(pid), '_blank');
      if (t) {
        t.onload = () => {
          // can not working
          t.document.title = i18n.language === 'zh' ? '设备检测' : 'device testing';
        };
      }
    },
    [i18n, memorizedGetTKUrl],
  );

  useEffect(() => {
    window.addEventListener('message', memorizedSetTestDeviceResult);
    return () => window.removeEventListener('message', memorizedSetTestDeviceResult);
  }, [memorizedSetTestDeviceResult]);

  useEffect(() => {
    memorizedGetTestDeviceResult();
  }, [memorizedGetTestDeviceResult]);

  const memorizedSetCourseReportRead = useCallback(
    async (courseId: string) => {
      const newCourseReportList = Array.from(new Set(courseReportList.concat([courseId])));
      const payload = {
        courseReportList: newCourseReportList,
      };
      await createDeviceDetect({
        studentCode: curStudent?.code as string,
        platform: 'PC',
        data: payload,
      });
      setCourseReportList(newCourseReportList);
      const newReportList = {
        ...reportList,
        ...payload,
      };
      setReportList(newReportList);
    },
    [courseReportList, curStudent, reportList],
  );
  const memorizedSetReport = useCallback(
    async (key: string, value: string) => {
      const newReportList = Array.from(new Set((reportList[key] || []).concat([value])));
      const payload = {
        [key]: newReportList,
      };
      await createDeviceDetect({
        studentCode: curStudent?.code as string,
        platform: 'PC',
        data: payload,
      });
      const newList = {
        ...reportList,
        ...payload,
      };
      console.log(newList, 'newList');
      setReportList(newList);
    },
    [reportList, curStudent, setReportList],
  );

  return {
    testResult,
    studentResult,
    courseReportList,
    memorizedSetCourseReportRead,
    memorizedSetReport,
    reportList,
    openWindow: memorizedHandleOpenTKWindow,
  };
};

export default useDeviceInfo;
